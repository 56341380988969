.warppipe-lading-page {
    position: relative;
    .header-lading-page-mobile {
        display: none;
        @media screen and (max-width: 1024px) {
            display: block;
            height: 72px;
            background-color: #050B20;
            width: 100vw;
            padding: 0px 16px;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 10000;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .list-menu-mobile {
                position: fixed;
                top: 72px;
                left: 0;
                width: 100vw;
                height: calc(100vh - 72px);
                background-color: #050B20;
                border-top: 1px solid rgba(255, 255, 255, 0.15);
                padding: 24px 0px;
                >*+* {
                    margin-top: 8px;
                }
                a {
                    color: #FFF;
                    font-family: 'Poppins';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    padding: 12px 16px;
                    display: block;
                }
                .btn-book {
                    margin: 24px 16px;
                    color: #1D2939;
                    text-align: center;
                    font-family: 'Poppins';
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    padding: 12px 24px;
                    border-radius: 24px;
                    background: #fff;
                }
            }
        }
    }
    .container-fluid {
        max-width: 1202px;
        margin: 0 auto;
        padding: 0 16px;
        position: relative;
        z-index: 1;
    }
    .box-top {
        width: 100%;
        height: 900px;
        background-image: url('../../assets/images/animation-1.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        animation: imageTransition 12s ease-out infinite;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url('../../assets/images/grid-line.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            z-index: 1;
        }
        .header-lading-page {
            height: 80px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            nav {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 48px;
                a {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    color: white;
                    cursor: pointer;
                }
            }
            .btn-book {
                padding: 12px 24px;
                border-radius: 24px;
                border: 1px solid #fff;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: #fff;
                background: transparent;

            }
            @media screen and (max-width: 1024px) {
                display: none;
            }
        }
        .content-top-lading-page {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            max-width: 898px;
            margin: 250px auto 0;
            .title-lading-page {
                color: #FFF;
                text-align: center;
                font-family: 'Syne';
                font-size: 80px;
                font-weight: 700;
                line-height: 88px;
                text-transform: uppercase;
            }
            .description-lading-page {
                margin-top: 24px;
                padding: 8px 20px;
                border-radius: 50px;
                border: 1px solid #FFF;
                background: rgba(255, 255, 255, 0.15);
                backdrop-filter: blur(25px);
                color: #FFF;
                font-family: 'Poppins';
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: uppercase;
            }
            @media screen and (max-width: 1024px) {
                max-width: 100%;
                padding-top: 190px;
                margin-top: 0;
                .title-lading-page {
                    font-size: 28px;
                    line-height: 36px;
                }
                .description-lading-page {
                    padding: 6px 16px;
                    margin-top: 8px;
                    font-size: 12px;
                }
            }
        }
        @media screen and (min-width: 1920px) {
            background-size: 100% 90%;
            background-position: top;
        }
        @media screen and (max-width: 1024px) {
            height: 439px;

        }
    }
    .section-2 {
        padding: 80px 0px;
        .content-section {
            margin-bottom: 160px;
            max-width: 1107px;
            display: inline-block;
            color: #FFF;
            font-family: 'Poppins';
            font-size: 40px;
            font-weight: 400;
            .line-gradient {
                background: linear-gradient(270deg, #FFB2FE 21.5%, #7178FF 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            @media screen and (max-width: 1024px) {
                font-size: 16px;
                margin-bottom: 40px;
            }
        }
        .image-section {
            max-width: 1170px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            img {
                width: 100%;
                max-width: 670px;
                @media screen and (max-width: 768px) {
                    max-width: 236px;
                }
            }
        }
    }
    #services {
        margin: 80px 0px;
        .title-section {
            color: #FFF;
            text-align: center;
            font-family: 'Syne';
            font-size: 48px;
            font-weight: 700;
            margin-bottom: 56px;
        }

        .list-service-desktop {
            .item-service {
                border-top: 1px solid rgba(255, 255, 255, 0.20);
                position: relative;
                overflow: hidden;
                transition: all 0.3s ease-out;
                &::before {
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: 70.69%;
                    background: url('../../assets/icons/radial-gradient-hover.svg');
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    z-index: -1;
                    transition: all 0.3s ease-out;
                    visibility: hidden;
                }
                .content {
                    padding: 48px 0px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 204px;
                    .title-and-link {
                        min-width: 499px;
                        .title-service {
                            color: #FFF;
                            font-family: 'Poppins';
                            font-size: 48px;
                            font-weight: 500;
                        }
                        .btn-service {
                            margin-top: 17px;
                            gap: 16px;
                            display: flex;
                            align-items: center;
                            color: #9D71FF;
                            font-family: 'Syne';
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            visibility: hidden;
                            height: 0;
                            transition: all 0.3s ease-out;
                        }
                    }
                    .description-service {
                        color: #E8E8E8;
                        font-family: 'Poppins';
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
                &:hover {
                    &::before {
                        visibility: visible;
                    }
                    .content {
                        .btn-service {
                            visibility: visible;
                            height: 24px;
                        }
                    }
                }
            }
            @media screen and (max-width: 1024px) {
                display: none;
            }
        }
        .list-service-mobile {
            display: none;
            .item-service {
                border-top: 1px solid rgba(255, 255, 255, 0.20);
                position: relative;
                overflow: hidden;
                .content {
                    padding: 32px 0px;
                    >*+* {
                        margin-top: 8px;
                    }
                    .title-service {
                        color: #FFF;
                        font-family: 'Poppins';
                        font-size: 18px;
                        font-weight: 600;
                    }
                    .btn-service {
                        gap: 16px;
                        display: flex;
                        align-items: center;
                        color: #9D71FF;
                        font-family: 'Syne';
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        .img {
                            width: 24px;
                            height: 20px;
                        }
                    }
                    .description-service {
                        color: #E8E8E8;
                        font-family: 'Poppins';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }
        @media screen and (max-width: 1024px) {
            margin: 64px 0px;
            .title-section {
                font-size: 20px;
                line-height: 36px;
            }
            .list-service-mobile {
                display: block;
            }
        }
    }

    .our-vision {
        padding: 80px 0px 120px 0px;
        .title-our-vision {
            color: #828997;
            font-family: 'Syne';
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        .desc {
            margin-top: 16px;
            color: #FFF;
            font-family: 'Poppins';
            font-size: 48px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .image-section {
            margin-left: auto;
            width: fit-content;
            margin-top: 150px;
            img {
                width: 100%;
            }
        }
        @media screen and (max-width: 1024px) {
            padding: 64px 0px 100px 0px;
            .title-our-vision {
                font-size: 24px;
            }
            .desc {
                font-size: 24px;
                line-height: 36px;
            }
        }
    }
    .our-mission {
        background-color: #fff;
        border-radius: 64px 64px 0px 0px;
        position: relative;
        height: 900px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url('../../assets/images/bg-our-mission-1.png');
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 64px 64px 0px 0px;
            animation: imageOurMission 10s ease-out infinite;
            z-index: 0;

        }
        .container-fluid {
            position: relative;
            z-index: 3;
        }
        .title-our-mission {
            color: #747474;
            font-family: 'Syne';
            font-size: 32px;
            font-weight: 700;
            padding-top: 124px;
        }
        .desc {
            color: #1F1F1F;
            font-family: 'Poppins';
            font-size: 48px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 16px;
        }
        .image-section {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;
            z-index: 2;
        }
        @media screen and (max-width: 1024px) {
            height: auto;
            justify-content: center;
            gap: 56px;
            border-radius: 24px 24px 0px 0px;
            &::before {
                border-radius: 24px 24px 0px 0px;
            }
            .title-our-mission {
                padding-top: 32px;
                font-size: 24px;
            }
            .desc {
                font-size: 24px;
                line-height: 36px;
            }
            .image-section {
                img {
                    width: 60%;
                }
            }
        }
    }
    .our-portfolio {
        position: relative;
        height: 650px;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url('../../assets/images/bg-our-portfolio.png');
            background-size: cover;
            background-repeat: no-repeat;
            z-index: -1;
        }
        .title-our-portfolio {
            padding-top: 115px;
            margin: 0 auto;
            color: #FFF;
            text-align: center;
            font-family: 'Syne';
            font-size: 48px;
            font-weight: 700;
            max-width: 615px;
        }
        .list-partner {
            margin: 64px auto 0 auto;
            max-width: 970px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 32px;
            flex-wrap: wrap;
        }
        @media screen and (max-width: 1024px) {
            height: 496px;
            &::before {
                background-image: url('../../assets/images/bg-our-portfolio-mobile.png');
            }
            .title-our-portfolio {
                padding-top: 52px;
                font-size: 20px;
                line-height: 32px;

            }
            .list-partner {
                margin: 48px auto 0 auto;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 24px 16px;
                img {
                    width: 100%;
                    height: 32px;
                    object-fit: contain;
                }
            }
        }
    }
    .join-newsletter {
        position: relative;
        padding: 100px 0px;
        .content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left {
                .title-section {
                    color: #98A2B3;
                    font-family: 'Syne';
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
                .desc {
                    color: #EAEAEA;
                    font-family: 'Poppins';
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    max-width: 631px;
                    margin-top: 16px;
                }
            }
        }
        @media screen and (max-width: 1024px) {
            padding: 64px 0px;
            .content {
                flex-direction: column;
                align-items: flex-start;
                gap: 64px;
                justify-content: center;
                .left {
                    .title-section {
                        font-size: 24px;
                    }
                    .desc {
                        font-size: 24px;
                    }
                }
                svg {
                    width: 100px;
                    height: 100px;
                }
            }
        }
    }
    .footer-lading-page {
        padding: 48px 0px;
        border-top: 1px solid rgba(255, 255, 255, 0.10);
        .content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left {
                display: flex;
                align-items: center;
                gap: 16px;
                .desc {
                    color: #BFC6D3;
                    font-family: 'Poppins';
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                }
            }
            .right {
                display: flex;
                align-items: center;
                gap: 24px;
            }
            @media screen and (max-width: 1024px) {
                flex-direction: column;
                align-items: flex-start;
                gap: 32px;
                justify-content: center;
                .left {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }
    }
}

@keyframes imageTransition {
    0% {
        background-image: url('../../assets/images/animation-1.png')
    }
    33% {
        background-image: url('../../assets/images/animation-2.png')
    }
    66% {
        background-image: url('../../assets/images//animation-3.png')
    }
    100% {
        background-image: url('../../assets/images/animation-1.png')
    }
}
@keyframes imageOurMission {
    0% {
        background-image: url('../../assets/images/bg-our-mission-1.png')
    }
    25% {
        background-image: url('../../assets/images/bg-our-mission-2.png')
    }
    50% {
        background-image: url('../../assets/images//bg-our-mission-3.png')
    }
    75% {
        background-image: url('../../assets/images/bg-our-mission-4.png')
    }
    100% {
        background-image: url('../../assets/images/bg-our-mission-1.png')
    }
}