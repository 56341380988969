.team-warppipe {
    max-width: 1202px;
    margin: 0 auto;
    padding: 0px 16px 98px 16px;
    .founder,
    .member {
        padding-top: 150px;
        >*+* {
            margin-top: 64px;
        }
        .title {
            font-size: 40px;
            font-weight: 700;
            color: #101828;
            font-family: 'Syne', sans-serif;
        }
        .list-founder {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 64px 30px;
        }

    }
    .employ-item {
        .image {
            aspect-ratio: 270/324;
            img {
                width: 100%;
            }
        }
        .info {
            margin-top: 24px;
            .name {
                font-size: 20px;
                font-weight: 500;
                line-height: 23.98px;
            }
            .position {
                font-size: 16px;
                font-weight: 500;
                color: #98A2B3;
            }
        }
    }
    @media screen and (max-width: 1184px) {

        padding-bottom: 64px;
        .founder,
        .member {
            margin-top: 64px;
            .title {
                font-size: 30px;
            }
            .list-founder {
                grid-template-columns: repeat(3, 1fr);
                gap: 24px 16px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .founder,
        .member {
            padding-top: 0px;
            .title {
                font-size: 30px;
            }
            .list-founder {
                grid-template-columns: repeat(2, 1fr);
                gap: 24px 16px;
            }
        }
        .employ-item {
            .info {
                margin-top: 16px;
                .name {
                    font-size: 16px;
                }
                .position {
                    font-size: 14px;
                }
            }
        }

    }

}