* {
  outline: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  word-break: break-word;
  font-family: "Poppins", sans-serif;
  background: #050B20;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
a {
  text-decoration: none;
  color: black;
}
ul {
  li {
    list-style: none;
  }
}